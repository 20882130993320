import TagManager from "react-gtm-module";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/footer";
import Home from "./pages/home";
import "./scss/app.scss";

const tagManagerArgs = {
  gtmId: "GTM-WQC9DVV",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
