import React from "react";
import "./index.scss";

const Home = () => {
  return (
    <React.Fragment>
      <div className="page">
        <div className="container">
          <h1 className="logo">美好私募股權</h1>
          <p>美好私募股權股份有限公司為美好證券100%轉投資子公司，美好證券於109年12月14日取得主管機關同意轉投資設立美好私募股權股份有限公司，並於110年4月16日完成設立登記。</p>
          <p>美好證券申設私募股權子公司除響應主管機關促進產業升級、活化資金外，由於美好證券擅於價值投資，子公司將專注價值的發現與創造，投資具有長期價值的企業為投資人帶來長期穩定之獲利，同時投入實體產業，協助籌募資金，促進產業轉型與升級，創造基金、投資人及產業三贏局面，期望能為我們的社會帶來長期美好的影響與改變。</p>
        </div>
      </div>
    </React.Fragment>

  );
};

export default Home;
