import React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <footer>
      <span className="companyName">美好私募股權股份有限公司</span>
      <br />
      <span className="phone">02-2508-0755</span>
      臺北市⺠生東路二段176號12樓
    </footer>
  );
};

export default Footer;
